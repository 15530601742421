export const boardLink = (boardId, boardTitle) => {
  return `/b/${boardId}-${(boardTitle || "")
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
    .replace(/--/g, "-")
    .replace(/^-|-$/g, "")}`;
};

export const boardReportLink = (boardId, boardTitle) => {
  return `/r/${boardId}-${(boardTitle || "")
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
    .replace(/--/g, "-")
    .replace(/^-|-$/g, "")}`;
};

export const cardLink = (boardId, boardTitle, cardId, cardTitle) => {
  return `/b/${boardId}-${(boardTitle || "")
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
    .replace(/--/g, "-")
    .replace(/^-|-$/g, "")}/${cardId}-${(cardTitle || "")
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-")
    .replace(/--/g, "-")
    .replace(/^-|-$/g, "")}`;
};

export const extractBoardAndCardIds = (link) => {
  // https://olotasks.com/b/wbv2jk4y4g-marketing-ebest/6694986d2f103349f68c492a-nh-ng-i-u-c-n-l-u--khi-thu-tr--d-nh-cho-2k6
  // domain: olotasks.com
  // boardId: wbv2jk4y4g
  // boardTitle: marketing-ebest
  // cardId: 6694986d2f103349f68c492a
  // cardTitle: nh-ng-i-u-c-n-l-u--khi-thu-tr--d-nh-cho-2k6
  // extract boardId and cardId from link and return as an object

  // if link not containts https://olotasks.com/b/, return null
  if (!link.includes("/b/")) return null;

  // define the regex pattern check if the link is valid
  const pattern = new RegExp(
    /\/b\/([a-z0-9]+)-([a-z0-9-]+)\/([a-z0-9]+)-([a-z0-9-]+)/
  );
  // check if the link is valid
  const isValid = pattern.test(link);
  // if the link is not valid, return null
  if (!isValid) return null;

  // extract boardId and cardId from the link
  const [_, boardId, boardTitle, cardId, cardTitle] = link.match(pattern);
  // return the extracted boardId and cardId
  return { boardId, boardTitle, cardId, cardTitle };
};

import axios from "axios";
import { openAlert } from "../Redux/Slices/alertSlice";
import {
  setPending,
  setCard,
  updateTitle,
  updateDescription,
  updateComment,
  deleteComment,
  addMember,
  deleteMember,
  createLabel,
  updateLabel,
  deleteLabel,
  updateLabelSelection,
  createChecklist,
  deleteChecklist,
  addChecklistItem,
  setChecklistItemCompleted,
  deleteChecklistItem,
  setChecklistItemText,
  updateStartDueDates,
  updateDateCompleted,
  deleteAttachment,
  updateAttachment,
  updateCover,
  updateEstimateTime,
  setCardActivities,
  updateCardDetail,
} from "../Redux/Slices/cardSlice";
import { updateCard } from "../Redux/Slices/boardSlice";

const baseUrl = process.env.REACT_APP_API_URL + "/card";

export const getCard = async (cardId, boardId, dispatch) => {
  dispatch(setPending(true));
  try {
    let response = "";

    await axios.get(baseUrl + "/" + boardId + "/" + cardId).then((res) => {
      response = res;
    });

    const card = await JSON.parse(JSON.stringify(response.data));
    dispatch(setCard(card));
    dispatch(setPending(false));
  } catch (error) {
    dispatch(setPending(false));
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const titleUpdate = async (cardId, boardId, title, dispatch) => {
  try {
    dispatch(updateTitle(title));

    return axios
      .put(baseUrl + "/" + boardId + "/" + cardId, {
        title: title,
      })
      .then((res) => {
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
        return res.data;
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const descriptionUpdate = async (
  cardId,
  boardId,
  { description, mentions },
  dispatch
) => {
  try {
    dispatch(updateDescription(description));

    return axios
      .put(baseUrl + "/" + boardId + "/" + cardId, {
        description: description,
        mentions: mentions,
      })
      .then((res) => {
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
        return res.data;
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const comment = async (
  cardId,
  boardId,
  { content, mentions },
  dispatch
) => {
  try {
    return await axios
      .post(baseUrl + "/" + boardId + "/" + cardId + "/add-comment", {
        text: content,
        mentions: mentions,
      })
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const commentUpdate = async (
  cardId,
  boardId,
  { content, mentions },
  commentId,
  dispatch
) => {
  try {
    dispatch(updateComment(commentId, content));

    await axios
      .put(baseUrl + "/" + boardId + "/" + cardId + "/" + commentId, {
        text: content,
        mentions: mentions,
      })
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const commentDelete = async (cardId, boardId, commentId, dispatch) => {
  try {
    dispatch(deleteComment(commentId));

    return await axios
      .delete(baseUrl + "/" + boardId + "/" + cardId + "/" + commentId)
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const memberAdd = async (
  cardId,
  boardId,
  memberId,
  memberName,
  memberColor,
  dispatch
) => {
  try {
    dispatch(addMember({ memberId, memberName, memberColor }));

    await axios
      .post(baseUrl + "/" + boardId + "/" + cardId + "/add-member", {
        memberId: memberId,
      })
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const memberDelete = async (cardId, boardId, memberId, dispatch) => {
  try {
    dispatch(deleteMember({ memberId }));
    return axios
      .delete(
        baseUrl +
          "/" +
          boardId +
          "/" +
          cardId +
          "/" +
          memberId +
          "/delete-member"
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const labelCreate = async (
  cardId,
  boardId,
  text,
  color,
  backColor,
  dispatch
) => {
  try {
    dispatch(
      createLabel({ _id: "notUpdated", text, color, backColor, selected: true })
    );

    await axios
      .post(baseUrl + "/" + boardId + "/" + cardId + "/create-label", {
        text,
        color,
        backColor,
      })
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
        return res.data;
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const labelUpdate = async (
  cardId,
  boardId,
  labelId,
  label,
  dispatch
) => {
  try {
    dispatch(
      updateLabel({
        labelId: labelId,
        text: label.text,
        color: label.color,
        backColor: label.backColor,
      })
    );

    await axios
      .put(
        baseUrl +
          "/" +
          boardId +
          "/" +
          cardId +
          "/" +
          labelId +
          "/update-label",
        label
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const labelDelete = async (cardId, boardId, labelId, dispatch) => {
  try {
    dispatch(deleteLabel(labelId));
    await axios
      .delete(
        baseUrl + "/" + boardId + "/" + cardId + "/" + labelId + "/delete-label"
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const labelUpdateSelection = async (
  cardId,
  boardId,
  labelId,
  selected,
  label,
  dispatch
) => {
  try {
    dispatch(
      updateLabelSelection({ labelId: labelId, selected: selected, label })
    );

    await axios
      .put(
        baseUrl +
          "/" +
          boardId +
          "/" +
          cardId +
          "/" +
          labelId +
          "/update-label-selection",
        { selected: selected }
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const checklistCreate = async (cardId, boardId, title, dispatch) => {
  try {
    dispatch(createChecklist({ _id: "notUpdated", title }));

    return axios
      .post(baseUrl + "/" + boardId + "/" + cardId + "/create-checklist", {
        title,
      })
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
        return res.data;
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const checklistDelete = async (
  cardId,
  boardId,
  checklistId,
  dispatch
) => {
  try {
    dispatch(deleteChecklist(checklistId));
    await axios
      .delete(
        baseUrl +
          "/" +
          boardId +
          "/" +
          cardId +
          "/" +
          checklistId +
          "/delete-checklist"
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const checklistItemAdd = async (
  cardId,
  boardId,
  checklistId,
  text,
  dispatch
) => {
  try {
    dispatch(
      addChecklistItem({
        checklistId: checklistId,
        _id: "notUpdated",
        text: text,
      })
    );
    return axios
      .post(
        baseUrl +
          "/" +
          boardId +
          "/" +
          cardId +
          "/" +
          checklistId +
          "/add-checklist-item",
        {
          text,
        }
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
        return res.data;
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const checklistItemCompletedSet = async (
  cardId,
  boardId,
  checklistId,
  checklistItemId,
  completed,
  dispatch
) => {
  try {
    dispatch(
      setChecklistItemCompleted({
        checklistId: checklistId,
        checklistItemId: checklistItemId,
        completed: completed,
      })
    );
    await axios
      .put(
        baseUrl +
          "/" +
          boardId +
          "/" +
          cardId +
          "/" +
          checklistId +
          "/" +
          checklistItemId +
          "/set-checklist-item-completed",
        {
          completed,
        }
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const checklistItemTextSet = async (
  cardId,
  boardId,
  checklistId,
  checklistItemId,
  text,
  dispatch
) => {
  try {
    dispatch(
      setChecklistItemText({
        checklistId: checklistId,
        checklistItemId: checklistItemId,
        text: text,
      })
    );

    await axios
      .put(
        baseUrl +
          "/" +
          boardId +
          "/" +
          cardId +
          "/" +
          checklistId +
          "/" +
          checklistItemId +
          "/set-checklist-item-text",
        {
          text,
        }
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const checklistItemDelete = async (
  cardId,
  boardId,
  checklistId,
  checklistItemId,
  dispatch
) => {
  try {
    dispatch(
      deleteChecklistItem({
        checklistId: checklistId,
        checklistItemId: checklistItemId,
      })
    );

    await axios
      .delete(
        baseUrl +
          "/" +
          boardId +
          "/" +
          cardId +
          "/" +
          checklistId +
          "/" +
          checklistItemId +
          "/delete-checklist-item"
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const startDueDatesUpdate = async (
  cardId,
  boardId,
  startDate,
  dueDate,
  dueTime,
  dispatch
) => {
  try {
    dispatch(updateStartDueDates({ startDate, dueDate, dueTime }));

    await axios
      .put(baseUrl + "/" + boardId + "/" + cardId + "/update-dates", {
        startDate,
        dueDate,
        dueTime,
      })
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const dateCompletedUpdate = async (
  cardId,
  boardId,
  completed,
  dispatch
) => {
  try {
    dispatch(updateDateCompleted(completed));

    await axios
      .put(baseUrl + "/" + boardId + "/" + cardId + "/update-date-completed", {
        completed,
      })
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const attachmentAdd = async (cardId, boardId, link, name, dispatch) => {
  try {
    let response = "";
    await axios
      .post(baseUrl + "/" + boardId + "/" + cardId + "/add-attachment", {
        link: link,
        name: name,
      })
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
        response = res;
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const attachmentDelete = async (
  cardId,
  boardId,
  attachmentId,
  dispatch
) => {
  try {
    dispatch(deleteAttachment(attachmentId));

    await axios
      .delete(
        baseUrl +
          "/" +
          boardId +
          "/" +
          cardId +
          "/" +
          attachmentId +
          "/delete-attachment"
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const attachmentUpdate = async (
  cardId,
  boardId,
  attachmentId,
  link,
  name,
  dispatch
) => {
  try {
    dispatch(
      updateAttachment({ attachmentId: attachmentId, link: link, name: name })
    );

    await axios
      .put(
        baseUrl +
          "/" +
          boardId +
          "/" +
          cardId +
          "/" +
          attachmentId +
          "/update-attachment",
        { link: link, name: name }
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const coverUpdate = async (
  cardId,
  listId,
  boardId,
  color,
  isSizeOne,
  dispatch
) => {
  try {
    dispatch(updateCover({ color: color, isSizeOne: isSizeOne }));

    await axios
      .put(baseUrl + "/" + boardId + "/" + cardId + "/update-cover", {
        color: color,
        isSizeOne: isSizeOne,
      })
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const estimateTimeUpdate = async (cardId, boardId, time, dispatch) => {
  try {
    dispatch(updateEstimateTime(time));
    let response = "";
    await axios
      .post(baseUrl + "/" + boardId + "/" + cardId + "/estimate-time", {
        time,
      })
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
        response = res;
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const addWorkingTime = async (
  cardId,
  boardId,
  time,
  comment,
  date,
  dispatch
) => {
  try {
    let response = "";
    await axios
      .post(baseUrl + "/" + boardId + "/" + cardId + "/add-time", {
        time,
        comment,
        date,
      })
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
        response = res;
      });
    return response.data;
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const updateWorkingTime = async (
  cardId,
  boardId,
  timeId,
  time,
  comment,
  date,
  dispatch
) => {
  try {
    return await axios
      .put(
        baseUrl + "/" + boardId + "/" + cardId + "/" + timeId + "/update-time",
        {
          time,
          comment,
          date,
        }
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
        return res;
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const deleteWorkingTime = async (
  cardId,
  listId,
  boardId,
  timeId,
  dispatch
) => {
  try {
    let response = "";
    await axios
      .delete(
        baseUrl + "/" + boardId + "/" + cardId + "/" + timeId + "/delete-time"
      )
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
        response = res;
      });

    return response.data;
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const uploadAttachment = async (cardId, boardId, files, dispatch) => {
  try {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const formData = new FormData();
      formData.append(`file`, file);
      await axios
        .post(
          baseUrl + "/" + boardId + "/" + cardId + "/upload-files",
          formData
        )
        .then((res) => {
          dispatch(
            updateCardDetail({ ...res.data.data, _id: res.data.cardId })
          );
          dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
          return res.data;
        });
    }

    return true;
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const uploadSingleAttachment = async (
  cardId,
  boardId,
  file,
  dispatch
) => {
  try {
    const formData = new FormData();
    formData.append(`file`, file);
    return await axios
      .post(baseUrl + "/" + boardId + "/" + cardId + "/upload-files", formData)
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
        return res.data;
      });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const loadCardActivities = async (boardId, cardId, dispatch) => {
  try {
    let response = "";
    await axios
      .get(baseUrl + "/" + boardId + "/" + cardId + "/card-activities")
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
        response = res;
      });

    dispatch(setCardActivities(response.data));
    return response;
  } catch (err) {}
};

export const cardShowHideCover = async (
  cardId,
  boardId,
  showCover,
  dispatch
) => {
  try {
    await axios
      .put(baseUrl + "/" + boardId + "/" + cardId + "/show-cover", {
        show: showCover,
      })
      .then((res) => {
        dispatch(updateCardDetail({ ...res.data.data, _id: res.data.cardId }));
        dispatch(updateCard({ ...res.data.data, _id: res.data.cardId }));
      });
  } catch (err) {}
};

export const cardAchieve = async (boardId, cardId, dispatch) => {
  try {
    return await axios.put(
      baseUrl + "/" + boardId + "/" + cardId + "/achieve-card"
    );
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const cardUnAchieve = async (boardId, cardId, dispatch) => {
  try {
    return await axios.put(
      baseUrl + "/" + boardId + "/" + cardId + "/unachieve-card"
    );
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

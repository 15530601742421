import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingScreen from "../Components/LoadingScreen";
import { useWebSocket } from "../Components/Websocket/WebSocketContext";
import { addNotification } from "../Redux/Slices/notificationSlice";
import {
  addCard,
  updateCard,
  deleteCard,
  updateCardOrder as boardUpdateCardOrder,
  updateListOrder as boardUpdateListOrder,
  updateList,
  deleteList,
  addList,
  updateBoard,
} from "../Redux/Slices/boardSlice";
import { updateCardDetail } from "../Redux/Slices/cardSlice";

export default function ProtectedArea({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const board = useSelector((state) => state.board);
  const card = useSelector((state) => state.card);
  const { ws } = useWebSocket();
  const location = useLocation();
  useEffect(() => {
    if (!user.isAuthenticated && !user.pending)
      navigate(`/login?redirect=${location.pathname}`);
  });

  useEffect(() => {
    if (!ws) return;
    if (user.isAuthenticated && !user.pending && ws) {
      ws.on("notification", (data) => {
        dispatch(addNotification(data));
      });
      ws.on("card.update", (data) => {
        dispatch(updateCard({ ...data.data, _id: data.cardId }));
        dispatch(updateCardDetail({ ...data.data, _id: data.cardId }));
      });
      ws.on("card.delete", (data) => {
        dispatch(deleteCard({ cardId: data.cardId, listId: data.listId }));
      });
      ws.on("card.add", (data) => {
        dispatch(addCard(data.data));
      });
      ws.on("card.order", (data) => {
        dispatch(boardUpdateCardOrder(data));
      });
      ws.on("list.update", (data) => {
        dispatch(updateList(data.data));
      });
      ws.on("list.delete", (data) => {
        dispatch(deleteList(data.listId));
      });
      ws.on("list.add", (data) => {
        dispatch(addList(data.data));
      });
      ws.on("list.order", (data) => {
        dispatch(boardUpdateListOrder(data));
      });
      ws.on("board.update", (data) => {
        dispatch(updateBoard(data.data));
      });
    }
    return () => {
      ws.off("notification");
      ws.off("card.update");
      ws.off("card.delete");
      ws.off("card.add");
      ws.off("card.order");
      ws.off("list.update");
      ws.off("list.delete");
      ws.off("list.add");
      ws.off("list.order");
      ws.off("board.update");
    };
  }, [user.isAuthenticated, user.pending, ws]);

  if (user.isAuthenticated && !user.pending) {
    return children;
  } else {
    return <LoadingScreen />;
  }
}

import { Upload } from "@mui/icons-material";
import React from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const UploadButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 2px dashed #d1d5db;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #3b82f6; /* Blue color on hover */
  }

  .icon {
    width: 48px;
    height: 48px;
    color: #3b82f6; /* Icon color */
  }

  .text {
    margin-top: 10px;
    color: #6b7280; /* Gray text color */
  }
`;

const UploadButton = ({ onFileSelected }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/webp": [],
    },
    multiple: false,
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024, // 5MB
    onDrop: (acceptedFiles) => {
      onFileSelected(acceptedFiles[0]);
    },
  });

  return (
    <UploadButtonWrapper
      {...getRootProps()}
      className={isDragActive ? "active" : ""}
    >
      <input {...getInputProps()} />
      <Upload />
      <p className="text">Upload your image (max 2MB)</p>
    </UploadButtonWrapper>
  );
};

export default UploadButton;

import { createSlice } from "@reduxjs/toolkit";
import { isEmpty, pick, uniq, uniqBy } from "lodash-es";

const initialState = {
  id: "",
  title: "",
  backgroundImageLink: "",
  isImage: true,
  lists: [],
  members: [],
  activity: [],
  loading: true,
  description: "",
  activityLoading: false,
  filters: {
    text: "",
    mentions: [],
    labels: [],
  },
  cards: {},
};

const getBardUpdatedValues = (data) => {
  const board = pick(data, [
    "title",
    "backgroundImageLink",
    "isImage",
    "description",
  ]);
  if (data.members) {
    board.members = data.members;
  }

  if (data.lists) {
    board.lists = data.lists;
  }

  if (data.labels) {
    board.labels = data.labels;
  }
  return board;
};

const convertData = (data) => {
  const card = pick(data, [
    "board",
    "cover",
    "owner",
    "date",
    "labels",
    "members",
    "watchers",
    "title",
    "_id",
  ]);

  if (data.comments) {
    card.commentsCount = data.comments.length;
  }

  if (data.attachments) {
    card.attachmentsCount = data.attachments.length;
  }

  if (data.checklists) {
    card.checklistsCount = data.checklists.length;
    card.checkListsDone = data.checklists.filter(
      (checklist) => checklist.complete
    ).length;
  }

  if (!isEmpty(data.description)) {
    card.hasDescription = !isEmpty(data.description);
  }

  if (
    card.timeTracking &&
    card.timeTracking.userTimeTracking &&
    card.timeTracking.userTimeTracking.length > 0
  ) {
    card.loggedTimeSum = card.timeTracking.userTimeTracking.reduce(
      (acc, curr) => acc + curr.loggedTime,
      0
    );
  }
  return card;
};

const boardSlice = createSlice({
  name: "board",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    successFetchingBoard: (state, action) => {
      state.id = action.payload._id;
      state.title = action.payload.title;
      state.backgroundImageLink = action.payload.backgroundImageLink;
      state.isImage = action.payload.isImage;
      state.lists = action.payload.lists;
      state.members = action.payload.members;
      state.description = action.payload.description;
      state.labels = action.payload.labels;
      state.shortId = action.payload.shortId;
      state.activity = action.payload.activity;
      state.filters = {
        text: "",
        mentions: [],
      };
    },
    setActivityLoading: (state, action) => {
      state.activityLoading = action.payload;
    },
    updateActivity: (state, action) => {
      state.activity = action.payload;
    },
    updatefilters: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.filters[key] = action.payload[key];
      });
    },
    updateMembers: (state, action) => {
      state.members = action.payload;
    },

    setCards: (state, action) => {
      const cards = {};
      action.payload.map((card) => {
        cards[card._id] = card;
      });
      state.cards = cards;
    },

    updateBoard: (state, action) => {
      const board = getBardUpdatedValues(action.payload);
      Object.keys(board).forEach((key) => {
        state[key] = board[key];
      });
    },

    updateCard: (state, action) => {
      state.cards[action.payload._id] = {
        ...state.cards[action.payload._id],
        ...convertData(action.payload),
      };
    },

    addCard: (state, action) => {
      state.cards[action.payload._id] = action.payload;
      state.lists = state.lists.map((list) => {
        if (list._id === action.payload.owner) {
          list.cards.push(action.payload._id);
        }
        list.cards = uniq(list.cards);
        return list;
      });
    },

    addList: (state, action) => {
      state.lists.push(action.payload);
      state.lists = uniqBy(state.lists, "_id");
    },

    updateList: (state, action) => {
      state.lists = [
        ...state.lists.map((list) => {
          if (list._id === action.payload._id) {
            return {
              ...list,
              ...action.payload,
            };
          }
          return list;
        }),
      ];
    },

    deleteList: (state, action) => {
      state.lists = state.lists.filter((list) => list._id !== action.payload);
    },

    deleteCard: (state, action) => {
      const { listId, cardId } = action.payload;
      state.lists = state.lists.map((list) => {
        if (list._id === listId) {
          list.cards = list.cards.filter((card) => card !== cardId);
        }
        return list;
      });
      delete state.cards[cardId];
    },
    updateListOrder: (state, action) => {
      const { listId, destinationIndex } = action.payload;
      const list = state.lists.find((list) => list._id === listId);
      state.lists = state.lists.filter((list) => list._id !== listId);
      state.lists.splice(destinationIndex, 0, list);
    },

    updateCardOrder: (state, action) => {
      const { sourceId, destinationId, destinationIndex, cardId } =
        action.payload;
      const sourceList = state.lists.find((list) => list._id === sourceId);
      const destinationList = state.lists.find(
        (list) => list._id === destinationId
      );
      sourceList.cards = sourceList.cards.filter((c) => c !== cardId);
      destinationList.cards.splice(destinationIndex, 0, cardId);
      destinationList.cards = uniq(destinationList.cards);
      state.lists = state.lists.map((list) => {
        if (list._id === sourceId) return sourceList;
        if (list._id === destinationId) return destinationList;
        return list;
      });
    },
  },
});

export const {
  setLoading,
  successFetchingBoard,
  setActivityLoading,
  addList,
  updateList,
  deleteList,
  updateActivity,
  updateMembers,
  updateBoard,
  updatefilters,
  setCards,
  updateListOrder,
  updateCardOrder,
  addCard,
  updateCard,
  deleteCard,
} = boardSlice.actions;
export default boardSlice.reducer;

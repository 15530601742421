import styled from "styled-components";
import { md } from "../../BreakPoints";

export default styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  ${md({
    gap: "0.7rem",
  })}
`;

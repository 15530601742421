import Navbar from "../../Components/Navbar";
import React, { useEffect, useState } from "react";
import TopBar from "../../Components/TopBar/TopBar";
import * as style from "./Styled";
import AddList from "../../Components/AddList/AddList";
import List from "../../Components/List/List";
import { useDispatch, useSelector } from "react-redux";
import { getBoard } from "../../Services/boardsService";
import { getAllCards, getLists } from "../../Services/boardService";
// import {
//   updateCardOrder,
//   updateListOrder,
// } from "../../Services/dragAndDropService";
import LoadingScreen from "../../Components/LoadingScreen";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";
import EditCard from "../../Components/Modals/EditCardModal/EditCard";
import { useWebSocket } from "../../Components/Websocket/WebSocketContext";
import { isEmpty } from "lodash-es";
import ConfirmModal from "../../Components/ConfirmModal";
import {
  updateCardOrder,
  updateListOrder,
} from "../../Services/dragAndDropService";
import { AutoTimeTrackingProvider } from "../../Components/AutoTimeTracking";

const Board = (props) => {
  /* props.match.params.id */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ws } = useWebSocket();
  let { id, cardId } = useParams();
  const {
    backgroundImageLink,
    isImage,
    loading,
    title,
    shortId,
    lists,
    members,
  } = useSelector((state) => state.board);
  const user = useSelector((state) => state.user);
  // const { allLists, loadingListService } = useSelector((state) => state.list);
  const [searchString, setSearchString] = useState("");
  const [boardId, boardTitle] = id.split("-");

  useEffect(() => {
    if (!user.isAuthenticated && !user.pending) navigate("/");
  });

  useEffect(() => {
    // prevent loading board again when data is loaded
    if (!isEmpty(title) && !loading && boardId == shortId) return;

    getBoard(boardId, dispatch);
    getAllCards(boardId, dispatch);
  }, [id, dispatch, boardId]);

  useEffect(() => {
    if (!ws) return;
    ws.emit("joinRoom", boardId);
    ws.on("connect", () => {
      ws.emit("joinRoom", boardId);
    });
    return () => {
      if (ws) {
        ws.emit("leaveRoom", boardId);
      }
    };
  }, [ws, boardId]);
  useEffect(() => {
    document.title = title + " | Olo Tasks";
  }, [title]);

  const onDragEnd = async (result) => {
    const { draggableId, source, destination } = result;
    if (!destination) return;
    if (result.type === "column") {
      if (source.index === destination.index) return;

      await updateListOrder(
        {
          sourceIndex: source.index,
          destinationIndex: destination.index,
          listId: draggableId,
          boardId: boardId,
          allLists: lists,
        },
        dispatch
      );
      return;
    }
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    )
      return;
    await updateCardOrder(
      {
        sourceId: source.droppableId,
        destinationId: destination.droppableId,
        sourceIndex: source.index,
        destinationIndex: destination.index,
        cardId: draggableId,
        boardId: boardId,
        allLists: lists,
      },
      dispatch
    );
  };

  const handleOpenClose = () => {
    navigate(`/b/${id}`);
    // setOpenModal((current) => !current);
  };

  if (isEmpty(title))
    return (
      <>
        <Navbar allowSearch />
        <style.Container isImage={false} color="blue">
          {loading && <LoadingScreen />}
          {!loading && (
            <ConfirmModal
              open
              title="Board Not Found!"
              closeHandle={() => {
                navigate("/boards");
              }}
            />
          )}
        </style.Container>
      </>
    );

  const loggedUser = members?.find((m) => m.user === user.userInfo._id);

  return (
    <>
      <AutoTimeTrackingProvider boardId={boardId}>
        <Navbar allowSearch />
        <style.Container
          isImage={isImage}
          bgImage={
            isImage ? backgroundImageLink.split("?")[0] : backgroundImageLink
          }
        >
          <TopBar activeMenu={"b"} />
          {loading && <LoadingScreen />}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId="all-columns"
              direction="horizontal"
              type="column"
              isDropDisabled={!["admin", "owner"].includes(loggedUser.role)}
            >
              {(provided, snapshot) => {
                return (
                  <style.ListContainer
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {!loading &&
                      lists?.map((list, index) => {
                        return (
                          list && (
                            <List
                              key={list._id}
                              index={index}
                              info={list}
                              boardId={boardId}
                            />
                          )
                        );
                      })}
                    {provided.placeholder}
                    {["admin", "owner"].includes(loggedUser.role) && (
                      <AddList boardId={boardId} />
                    )}
                  </style.ListContainer>
                );
              }}
            </Droppable>
          </DragDropContext>
        </style.Container>
        {cardId && (
          <EditCard
            open={true}
            callback={handleOpenClose}
            ids={{
              cardId: cardId,
              boardId: boardId,
            }}
          />
        )}
      </AutoTimeTrackingProvider>
    </>
  );
};

export default Board;

import { Avatar } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  LeftContainer,
  RightContainer,
  LogWrapper,
  Title,
  Date,
} from "./styled";
import { loadCardActivities } from "../../../../Services/cardService";
import CardLoadingSvg from "../../../../Images/cardLoading.svg";
import { LoadingScreen } from "../styled";
import AvatarIcon from "../../../AvatarIcon";
import { orderBy } from "lodash-es";

const ActivityLog = ({ sort }) => {
  const card = useSelector((state) => state.card);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (card.activities == undefined) {
      loadCardActivities(card.boardId, card.cardId, dispatch).then((data) => {
        setLoading(false);
      });
    }
  }, []);

  if (loading) {
    return (
      <Container>
        <LoadingScreen image={CardLoadingSvg} />
      </Container>
    );
  }
  return (
    <>
      {orderBy(card.activities || [], ["createdAt"], sort)?.map(
        (activity, index) => {
          if (!activity.isComment)
            return (
              <Container key={index}>
                <LeftContainer>
                  <AvatarIcon
                    id={activity.user}
                    name={activity.userName}
                    color={activity.color}
                  />
                </LeftContainer>
                <RightContainer>
                  <LogWrapper>
                    <Title>{activity.userName}</Title>{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: activity.text }}
                    ></span>
                  </LogWrapper>
                  <Date>
                    {moment(activity.createdAt).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </Date>
                </RightContainer>
              </Container>
            );
          return undefined;
        }
      )}
    </>
  );
};

export default ActivityLog;

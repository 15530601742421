import React, { useEffect, useState } from "react";
import { cardLink, extractBoardAndCardIds } from "../../../../Utils/linkHelper";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default ({ text }) => {
  const board = useSelector((state) => state.board);
  const [task, setTask] = useState(null);
  useEffect(() => {
    const cardData = extractBoardAndCardIds(text);

    if (cardData) {
      const card = board.cards[cardData.cardId];
      if (!card) return;
      const list = board.lists.find((l) => l._id === card.owner);
      setTask({ ...card, ...cardData, listTitle: list.title });
    }
  }, [text]);
  return (
    <div>
      {task ? (
        <Link
          to={cardLink(
            task.boardId,
            task.boardTitle,
            task.cardId,
            task.cardTitle
          )}
        >
          {task.title}
          <b>({task.listTitle})</b>
        </Link>
      ) : (
        text
      )}
    </div>
  );
};

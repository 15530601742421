import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash-es";
import EditCard from "../../../../Components/Modals/EditCardModal/EditCard";

const localizer = dayjsLocalizer(dayjs);

export default () => {
  let { id, cardId } = useParams();
  const board = useSelector((state) => state.board);
  const report = useSelector((state) => state.report);
  const [viewDetail, setViewDetail] = useState(null);
  const [boardId, boardTitle] = id.split("-");
  const [data, setData] = useState([]);
  const listMapping = {};
  board.lists.forEach((l) => {
    listMapping[l._id] = l.title;
  });
  const filterData = () => {
    const { members, labels, columns, dateRange } = report.filter;

    const allCards = Object.keys(board.cards).map((k) => {
      const c = board.cards[k];
      return {
        ...c,
        label: c.label,
        listTitle: listMapping[c.listId],
        members: c.members,
      };
    });
    const result = allCards
      .filter((c) => {
        if (isEmpty(c.date?.dueDate)) return false;

        if (labels.length > 0 && !labels.includes(c.label)) return false;

        if (columns.length > 0 && !columns.includes(c.owner)) return false;

        if (
          members.length > 0 &&
          !c.members.map((s) => s.user).some((s) => members.includes(s))
        )
          return false;

        return true;
      })
      .sort((a, b) => {
        return dayjs(a.date.dueDate).diff(dayjs(b.date.dueDate));
      });
    setData(result);
  };

  useEffect(() => {
    if (board.loading) return;
    filterData();
  }, [report.filter]);

  const handleOpenClose = () => {
    setViewDetail(null);
  };
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", padding: "1rem" }}>
      <Calendar
        localizer={localizer}
        events={data.map((c) => ({
          title: c.title,
          start: dayjs(c.date.dueDate).toDate(),
          end: dayjs(c.date.dueDate).add(1, "hour").toDate(),
          ...c,
        }))}
        onSelectEvent={(event) => {
          console.log(event);
          setViewDetail(event);
        }}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "calc(100vh - 3rem - 140px)" }}
      />
      {viewDetail && (
        <EditCard
          open={true}
          callback={handleOpenClose}
          ids={{
            cardId: viewDetail._id,
            boardId: boardId,
          }}
        />
      )}
    </Paper>
  );
};

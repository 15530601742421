import React, { useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import FormGroup from "../../../../Components/ReUsableComponents/FormGroup";
import FormCard from "../../../../Components/ReUsableComponents/FormCard";
import Form from "../../../../Components/ReUsableComponents/Form";
import FormLabel from "../../../../Components/ReUsableComponents/FormLabel";
import { Input } from "../../../../Components/ReUsableComponents/Input";
import Button from "../../../../Components/ReUsableComponents/Button";
import { upperFirst } from "lodash-es";
import { updateProfile } from "../../../../Services/userService";
import { openAlert } from "../../../../Redux/Slices/alertSlice";
import { updateUserInfor } from "../../../../Redux/Slices/userSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import FormErrorLabel from "../../../../Components/ReUsableComponents/FormErrorLabel";
import { AvatarContainer, InforContainer, TopAreaContainer } from "./Styled";
import AvatarEditor from "../AvartarEditor";
import { Avatar } from "@mui/material";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  surename: yup.string().required("Surename is required"),
});

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userInfo);
  const [isEdit, setIsEdit] = useState(false);

  const {
    register,
    setValue,
    watch,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: user.name,
      surename: user.surename,
      color: user.color,
    },
  });
  const values = watch();

  const onSubmit = (data) => {
    if (isEdit) {
      updateProfile(data)
        .then((res) => {
          dispatch(
            openAlert({
              message: "Profile updated successfully",
              severity: "success",
              duration: 500,
            })
          );
          setIsEdit(false);
          dispatch(updateUserInfor({ ...user, ...data }));
        })
        .catch((err) => {
          dispatch(
            openAlert({
              message: err?.response?.data?.errMessage
                ? err.response.data.errMessage
                : err.message,
              severity: "error",
            })
          );
        });
      console.log(data);
    } else {
      setIsEdit(true);
    }
  };

  const cancelHandle = () => {
    reset();
    setIsEdit(false);
  };

  const fullName = `${upperFirst(values.name)} ${upperFirst(
    values.surename
  )}`.trim();

  return (
    <FormCard size="lg">
      <TopAreaContainer>
        <AvatarContainer>
          <AvatarEditor avatar={user.avatar} />
        </AvatarContainer>
        <InforContainer>
          <FormGroup>
            <FormLabel>Display Name: {fullName}</FormLabel>
          </FormGroup>
          <FormGroup>
            <FormLabel>Email</FormLabel>
            <Input value={user.email} readOnly={true} disabled={true} />
          </FormGroup>
        </InforContainer>
      </TopAreaContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>Sure Name</FormLabel>
          <Input
            {...register("surename")}
            readOnly={!isEdit}
            disabled={!isEdit}
          />
          {errors.surename && (
            <FormErrorLabel>{errors.surename.message}</FormErrorLabel>
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel>Last Name</FormLabel>
          <Input {...register("name")} readOnly={!isEdit} disabled={!isEdit} />
          {errors.name && (
            <FormErrorLabel>{errors.name.message}</FormErrorLabel>
          )}
        </FormGroup>
        <FormGroup row>
          {!isEdit && (
            <Button color={"success"} onClick={() => setIsEdit(true)}>
              Edit
            </Button>
          )}
          {isEdit && (
            <>
              <Button color={"success"}>Save</Button>
              <Button color={"danger"} onClick={cancelHandle}>
                Cancel
              </Button>
            </>
          )}
        </FormGroup>
      </Form>
    </FormCard>
  );
};

export default Profile;

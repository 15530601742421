import React from "react";
import Button from "./ReUsableComponents/Button";
import AvatarIcon from "./AvatarIcon";
import { useDispatch, useSelector } from "react-redux";
import { updatefilters } from "../Redux/Slices/boardSlice";

export default function MyTasksButton() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userInfo);
  const filters = useSelector((state) => state.board.filters);
  const isActive =
    filters.mentions.length == 1 && filters.mentions[0] == user._id;
  const myTasksClick = () => {
    dispatch(
      updatefilters({
        mentions: isActive ? [] : [user._id],
        labels: [],
        text: "",
      })
    );
  };
  return (
    <Button color={isActive ? "success" : "default"} onClick={myTasksClick}>
      <AvatarIcon
        sx={{
          width: "20px",
          height: "20px",
          fontSize: "0.6rem",
          marginRight: 1,
        }}
        {...user}
      />{" "}
      My Tasks
    </Button>
  );
}

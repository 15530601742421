import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import userReducer from "./Slices/userSlice";
import alertReducer from "./Slices/alertSlice";
import boardReducer from "./Slices/boardSlice";
import cardReducer from "./Slices/cardSlice";
import notificationReducer from "./Slices/notificationSlice";
import reportReducer from "./Slices/reportSlice";

const reducer = {
  user: userReducer,
  alert: alertReducer,
  board: boardReducer,
  card: cardReducer,
  notification: notificationReducer,
  report: reportReducer,
};
const Store =
  process.env.NODE_ENV == "development"
    ? configureStore({
        reducer: reducer,
        devTools: true,
        middleware: (getDefaultMiddleware) =>
          getDefaultMiddleware().concat(createLogger()),
        // Other middleware or configuration options can be added here
      })
    : configureStore({
        reducer: reducer,
      });
export default Store;

import axios from "axios";

import { openAlert } from "../Redux/Slices/alertSlice";
import { updateListId } from "../Redux/Slices/cardSlice";
import {
  updateListOrder as boardUpdateListOrder,
  updateCardOrder as boardUpdateCardOrder,
} from "../Redux/Slices/boardSlice";

const baseUrl = process.env.REACT_APP_API_URL + "/list";

//  Create promise to queue requests
let submitCall = Promise.resolve();

export const updateCardOrder = async (props, dispatch) => {
  // await dispatch(updateCardDragDrop(tempList));
  dispatch(updateListId(props.destinationId));
  // Server side requests

  submitCall = submitCall.then(() =>
    axios.post(baseUrl + "/change-card-order", {
      boardId: props.boardId,
      sourceId: props.sourceId,
      destinationId: props.destinationId,
      destinationIndex: props.destinationIndex,
      cardId: props.cardId,
    })
  );
  dispatch(
    boardUpdateCardOrder({
      sourceId: props.sourceId,
      destinationId: props.destinationId,
      destinationIndex: props.destinationIndex,
      cardId: props.cardId,
    })
  );
  try {
    await submitCall;
  } catch (error) {
    submitCall = Promise.resolve();
    // await dispatch(updateCardDragDrop(savedList));
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const updateListOrder = async (props, dispatch) => {
  // Server side requests
  submitCall = submitCall.then(() =>
    axios.post(baseUrl + "/change-list-order", {
      boardId: props.boardId,
      sourceIndex: props.sourceIndex,
      destinationIndex: props.destinationIndex,
      listId: props.listId,
    })
  );
  dispatch(
    boardUpdateListOrder({
      listId: props.listId,
      destinationIndex: props.destinationIndex,
    })
  );
  try {
    await submitCall;
  } catch (error) {
    submitCall = Promise.resolve();
    // await dispatch(updateCardDragDrop(savedOrder));
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

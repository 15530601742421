import { Menu, MenuItem } from "@mui/material";
import styled from "styled-components";
import { xs } from "../../BreakPoints";

export const MenuStyled = styled(Menu)`
  max-width: 300px;
  width: 100%;
`;

export const MenuItemStyled = styled(MenuItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.75em;
`;

export const Container = styled.div`
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
  ${xs({
    paddingLeft: "1rem",
  })};
`;

export const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Title = styled.div`
  color: #5e6c84;
  font-size: 0.85rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.3rem;
`;

export const Avatar = styled.div`
  display: flex;
  border-radius: 50%;
  background-color: #5e6c84;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: white;
  font-size: 0.85rem;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
`;

export const AddAvatar = styled(Avatar)`
  background-color: rgba(0, 0, 0, 0.06);
  color: #172b4d;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: 2rem;
  min-width: 2.5rem;
  font-size: 0.85rem;
  font-weight: 800;
  padding: 0rem 0.75rem;
  color: white;
  width: auto;
  background-color: ${(props) => props.color};
`;

export const AddLabel = styled(Label)`
  background-color: rgba(0, 0, 0, 0.06);
  color: #42526e;
  min-width: 2rem;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

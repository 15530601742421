import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const SideBar = ({ menus }) => {
  return (
    <List sx={{ width: "100%" }}>
      {(menus || []).map((s) => (
        <ListItem disablePadding>
          <ListItemButton
            style={{ padding: "0 0.5em" }}
            LinkComponent={Link}
            to={s.to}
          >
            <ListItemText primary={s.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default SideBar;

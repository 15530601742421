import React from "react";
import { useSelector } from "react-redux";
import BasePopover from "../../../ReUsableComponents/BasePopover";
import { Title, RowContainer, AddAvatar } from "./styled";
import { Avatar } from "@mui/material";
import AvatarIcon from "../../../AvatarIcon";
import Members from "../Popovers/Members";
const MembersFeature = (props) => {
  const card = useSelector((state) => state.card);
  const board = useSelector((state) => state.board);
  const [memberPopover, setMemberPopover] = React.useState(null);
  return (
    <>
      <Title>Members</Title>
      <RowContainer>
        {card.members.map((member, index) => {
          return <AvatarIcon id={member.user} {...member} />;
        })}
        <AddAvatar onClick={(event) => setMemberPopover(event.currentTarget)}>
          +
        </AddAvatar>
      </RowContainer>
      {memberPopover && (
        <BasePopover
          anchorElement={memberPopover}
          closeCallback={() => {
            setMemberPopover(null);
          }}
          title="Members"
          contents={<Members />}
        />
      )}
    </>
  );
};

export default MembersFeature;

import axios from "axios";
import { openAlert } from "../Redux/Slices/alertSlice";
import {
  deleteList,
  setActivityLoading,
  setCards,
  updateActivity,
  setLoading,
  updateList,
  updateBoard,
  addList,
  addCard,
  deleteCard,
} from "../Redux/Slices/boardSlice";

const listRoute = process.env.REACT_APP_API_URL + "/list";
const boardRoute = process.env.REACT_APP_API_URL + "/board";
const baseUrl = process.env.REACT_APP_API_URL + "/card";
export const getAllCards = async (boardId, dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await axios.post(`${boardRoute}/${boardId}/all-cards`);
    dispatch(setCards(res.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const activityUpdate = async (boardId, dispatch) => {
  dispatch(setActivityLoading(true));
  try {
    return await axios
      .get(boardRoute + "/" + boardId + "/activity")
      .then((res) => {
        dispatch(updateActivity(res.data));
        dispatch(setActivityLoading(false));
        return res.data;
      });
  } catch (error) {
    dispatch(setActivityLoading(false));
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const createList = async (title, boardId, dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await axios.post(listRoute + "/create", {
      title: title,
      boardId: boardId,
    });
    dispatch(addList(res.data));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const DeleteList = async (listId, boardId, dispatch) => {
  dispatch(setLoading(true));
  try {
    await axios.delete(listRoute + "/" + boardId + "/" + listId);
    await dispatch(deleteList(listId));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const listTitleUpdate = async (listId, boardId, title, dispatch) => {
  try {
    await dispatch(updateList({ _id: listId, title: title }));
    await axios.put(
      listRoute + "/" + boardId + "/" + listId + "/update-title",
      { title: title }
    );
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const boardDescriptionUpdate = async (
  boardId,
  description,
  dispatch
) => {
  try {
    await dispatch(updateBoard({ description }));
    await axios.put(`${boardRoute}/${boardId}/update-board-description`, {
      description,
    });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const boardBackgroundUpdate = async (
  boardId,
  background,
  isImage,
  dispatch
) => {
  try {
    await dispatch(updateBoard({ backgroundImageLink: background, isImage }));
    await axios.put(`${boardRoute}/${boardId}/update-background`, {
      background,
      isImage,
    });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const boardMemberAdd = async (boardId, members, dispatch) => {
  try {
    const result = await axios.post(`${boardRoute}/${boardId}/add-member`, {
      members,
    });
    await dispatch(updateBoard({ members: result.data }));
    dispatch(
      openAlert({
        message: "Members are added to this board successfully",
        severity: "success",
      })
    );
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const boardAddMemberByEmails = async (boardId, emails, dispatch) => {
  try {
    const result = await axios.post(
      `${boardRoute}/${boardId}/add-member-by-emails`,
      {
        emails,
      }
    );
    await dispatch(updateBoard({ members: result.data }));
    dispatch(
      openAlert({
        message: "Members are added to this board successfully",
        severity: "success",
      })
    );
    return result.data;
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const boardAddMemberByEmail = async (boardId, email, dispatch) => {
  try {
    const result = await axios.post(
      `${boardRoute}/${boardId}/add-member-by-email`,
      {
        email,
      }
    );
    await dispatch(updateBoard({ members: result.data }));
    dispatch(
      openAlert({
        message: "Member is added to this board successfully",
        severity: "success",
      })
    );
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const boardMemberDelete = async (boardId, memberId) => {
  return await axios.delete(`${boardRoute}/${boardId}/member/${memberId}`);
};

export const boardMemberLeave = async (boardId) => {
  return await axios.delete(`${boardRoute}/${boardId}/member/leave`);
};

export const boardMemberResendInvite = async (boardId, email) => {
  return await axios.post(`${boardRoute}/${boardId}/member/resend-invite`, {
    email,
  });
};

export const boardMemberChangeRole = async (boardId, memberId, role) => {
  return await axios.put(`${boardRoute}/${boardId}/member/change-role`, {
    memberId,
    role,
  });
};

export const createCard = async (title, listId, boardId, dispatch) => {
  try {
    const updatedList = await axios.post(baseUrl + "/create", {
      title: title,
      listId: listId,
      boardId: boardId,
    });
    dispatch(addCard(updatedList.data));
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const cardDelete = async (listId, boardId, cardId, dispatch) => {
  try {
    dispatch(deleteCard({ listId, cardId }));
    return await axios.delete(
      baseUrl + "/" + boardId + "/" + cardId + "/delete-card"
    );
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const deleteBoard = async (boardId, dispatch) => {
  try {
    return axios.delete(`${boardRoute}/${boardId}`);
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

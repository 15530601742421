import React from "react";
import { Route, Routes } from "react-router-dom";
import { Container, LeftWrapper, RightWrapper, Wrapper } from "./Styled";
import Navbar from "../../Components/Navbar";
import SideBar from "./components/SideBardMenu";
import _nav from "./profileNav";
import routes from "./settingsRoutes";
const Settings = () => {
  return (
    <Container>
      <Navbar />
      <Wrapper>
        <LeftWrapper>
          <SideBar menus={_nav} />
        </LeftWrapper>
        <RightWrapper>
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  element={route.element}
                />
              );
            })}
          </Routes>
        </RightWrapper>
      </Wrapper>
    </Container>
  );
};

export default Settings;

import ChangePassword from "./components/ChangePassword";
import Profile from "./components/Profile";

export default [
  {
    path: "/",
    name: "Your Profile",
    exact: true,
    element: <Profile />,
  },
  {
    path: "profile",
    name: "Your Profile",
    exact: true,
    element: <Profile />,
  },
  {
    path: "change-password",
    name: "Change Passowrd",
    exact: true,
    element: <ChangePassword />,
  },
];

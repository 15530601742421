import Modal from "@mui/material/Modal";
import {
  ButtonContainer,
  DeleteIcon,
  EditIcon,
  ModelContainer,
  PopoverContainer,
} from "./Styled";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { secondsToTimeString } from "../../../../Utils/estimateTimeHelper";
import Button from "../../../ReUsableComponents/Button";
import { Delete } from "@mui/icons-material";
import { useState } from "react";
import BasePopover from "../../../ReUsableComponents/BasePopover";
import EstimateTimePopover from "../Popovers/EstimateTime/EstimateTimePopover";
import { BlueButton, RedButton } from "../Popovers/Labels/styled";
import { useDispatch, useSelector } from "react-redux";
import {
  comment,
  deleteWorkingTime,
  updateWorkingTime,
} from "../../../../Services/cardService";
import Close from "@mui/icons-material/Close";
import TaskComment from "./TaskCommen";
import AddWorkingTimePopover from "../Popovers/AddWokringTime/AddWorkingTimePopover";

const UserTimeTrackingModel = (props) => {
  const { open, callback } = props;
  const dispatch = useDispatch();
  const [deletePopover, setDeletePopover] = useState(null);
  const timeTracking = useSelector((state) => state.card.timeTracking);
  const thisCard = useSelector((state) => state.card);
  const user = useSelector((state) => state.user.userInfo);
  const [time, setTime] = useState(null);
  const [editTime, setEditTime] = useState(null);
  const [editPopover, setEditPopover] = useState(null);
  const handleDeleteTimeTracking = async () => {
    if (!time || user._id != time.user) return;
    await deleteWorkingTime(
      thisCard.cardId,
      thisCard.listId,
      thisCard.boardId,
      time._id,
      dispatch
    );
    handleCloseConfirm();
  };

  const handleCloseConfirm = () => {
    setDeletePopover(null);
    setTime(null);
  };
  const onTimeCommentChanged = (data) => {
    if (user._id != data.user) return;
    updateWorkingTime(
      thisCard.cardId,
      thisCard.boardId,
      data._id,
      data.loggedTime,
      data.comment,
      null,
      dispatch
    );
  };
  return (
    <Modal open={open} onClose={callback} style={{ overflow: "auto" }}>
      <ModelContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <h3>Time Tracking</h3>
          <Close onClick={callback} style={{ cursor: "pointer" }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
          }}
        >
          <span style={{ marginRight: "0.5em" }}>
            Logged Time: {secondsToTimeString(timeTracking.spentTime)}
          </span>
          <span style={{ marginRight: "0.5em" }}>
            Estimated Time: {secondsToTimeString(timeTracking.estimateTime)}
          </span>
        </div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: "auto", padding: "0.5em" }}
            aria-label="simple table"
          >
            <TableBody>
              {timeTracking.userTimeTracking.map((row) => {
                return user._id == row.user ? (
                  <TableRow
                    key={row.userName}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      td: { padding: "5px" },
                    }}
                  >
                    <TableCell align="left" size="small" sx={{ with: 100 }}>
                      {new Date(row.date).toLocaleDateString()}
                    </TableCell>
                    <TableCell scope="row">{row.userName}</TableCell>

                    <TableCell align="left">{row.comment}</TableCell>
                    <TableCell align="right">
                      {secondsToTimeString(row.loggedTime)}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Edit">
                        <EditIcon
                          fontSize="small"
                          onClick={(event) => {
                            setEditPopover(event.currentTarget);
                            setEditTime(row);
                          }}
                        />
                      </Tooltip>
                      {editPopover && (
                        <BasePopover
                          anchorElement={editPopover}
                          closeCallback={() => {
                            setEditPopover(null);
                          }}
                          title="Add Time"
                          contents={
                            <AddWorkingTimePopover
                              data={editTime}
                              closeCallback={() => {
                                setEditPopover(null);
                              }}
                            />
                          }
                        />
                      )}

                      <Tooltip title="Delete">
                        <DeleteIcon
                          style={{
                            cursor: "pointer",
                          }}
                          fontSize="small"
                          color="error"
                          onClick={(event) => {
                            setDeletePopover(event.currentTarget);
                            setTime(row);
                          }}
                        />
                      </Tooltip>
                      {deletePopover && (
                        <BasePopover
                          anchorElement={deletePopover}
                          closeCallback={handleCloseConfirm}
                          title="Are you sure?"
                          contents={
                            <PopoverContainer>
                              <ButtonContainer>
                                <RedButton
                                  style={{ width: "4rem" }}
                                  onClick={() => handleDeleteTimeTracking()}
                                >
                                  Yes
                                </RedButton>
                                <BlueButton
                                  style={{ width: "4rem" }}
                                  onClick={handleCloseConfirm}
                                >
                                  Cancel
                                </BlueButton>
                              </ButtonContainer>
                            </PopoverContainer>
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    key={row.userName}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      td: { padding: "5px" },
                    }}
                  >
                    <TableCell align="left" size="small" sx={{ with: 100 }}>
                      {new Date(row.date).toLocaleDateString()}
                    </TableCell>
                    <TableCell scope="row">{row.userName}</TableCell>

                    <TableCell align="left">{row.comment}</TableCell>
                    <TableCell align="right">
                      {secondsToTimeString(row.loggedTime)}
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </ModelContainer>
    </Modal>
  );
};

export default UserTimeTrackingModel;

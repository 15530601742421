import React, { useEffect } from "react";
import { Container } from "./styled";
import BaseSelectItems from "../../../../Popovers/BaseSelectItems";
import Button from "../../../../ReUsableComponents/Button";
import { useSelector } from "react-redux";
import { Checkbox, Input, ListItemText, MenuItem, Select } from "@mui/material";
import Title from "../../../../ReUsableComponents/Title";
import { myAdminBoards } from "../../../../../Services/boardsService";

export default ({ value, onChange, onClose }) => {
  const board = useSelector((state) => state.board);
  const user = useSelector((state) => state.user.userInfo);
  const [adminBoards, setAdminBoards] = React.useState([
    {
      _id: board.id,
      title: board.title,
      lists: board.lists,
      shortId: board.shortId,
    },
  ]);
  const [list, setList] = React.useState(value);
  const [selectedBoard, setSelectedBoard] = React.useState({
    _id: board.id,
    title: board.title,
    lists: board.lists,
    shortId: board.shortId,
  });
  let boardMember = board.members.find((m) => m.user == user._id);

  useEffect(() => {
    myAdminBoards().then((res) => {
      setAdminBoards(res.data);
    });
  }, []);

  const changeHandler = (data) => {
    setList(data.id);
  };

  const changeButtonHandler = () => {
    if (value == list || !list) return;
    onChange({
      boardId: selectedBoard.shortId,
      sourceId: value,
      destinationId: list,
    });
  };

  const cloneButtonHandler = () => {
    if (value == list || !list) return;
    onChange({
      boardId: selectedBoard.shortId,
      sourceId: value,
      destinationId: list,
      clone: true,
    });
  };

  const handleBoardChange = (event) => {
    const { target } = event;
    setSelectedBoard(target.value);
    if (target.value._id == board.id) {
      setList(value);
    } else {
      setList(target.value.lists[0]?._id);
    }
  };

  return (
    <Container>
      {["owner", "admin"].includes(boardMember.role) && (
        <>
          <Title>Board</Title>
          <Select
            value={selectedBoard}
            onChange={handleBoardChange}
            input={<Input label="Tag" />}
            renderValue={(selected) => selected.title}
          >
            {adminBoards.map((b) => (
              <MenuItem key={b._id} value={b}>
                <Checkbox checked={selectedBoard._id == b._id} />
                <ListItemText primary={b.title} />
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      <Title style={{ marginTop: "1em" }}>Column</Title>
      <BaseSelectItems
        selectedItems={list ? [list] : []}
        items={selectedBoard.lists.map((s) => ({
          id: s._id,
          name: s.title,
        }))}
        handleItemClick={changeHandler}
      />
      <Button
        color={value != list ? "info" : "default"}
        onClick={changeButtonHandler}
        disabled={value == list || !list}
      >
        Move
      </Button>
      {board.id != selectedBoard._id && (
        <Button
          color={value != list ? "info" : "default"}
          onClick={cloneButtonHandler}
          disabled={value == list || !list}
        >
          Clone
        </Button>
      )}
      <Button onClick={onClose}>Cancel</Button>
    </Container>
  );
};

export default [
  {
    name: "Your Profile",
    to: "profile",
  },
  {
    name: "Change Password",
    to: "change-password",
  },
];

import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  outline: none;
  font-size: 0.85rem;
  border-radius: 3px;
  padding: 5px 10px;
  border: 2px solid #dfe1e6;
  background-color: #fff;
  &[disabled] {
    background-color: #f4f5f7;
    color: #c2c6dc;
  }
  &:focus {
    transition: background-color 0.2s ease-in-out 0s,
      border-color 0.2s ease-in-out 0s;
    border: 2px solid #68bcff;
  }
`;

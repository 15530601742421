import styled from "styled-components";

export const Container = styled.div`
  background: skyblue;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
`;

export const Title = styled.h1`
  cursor: default;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  width: 100vw;
  margin-bottom: 1rem;
  color: lightblue;
  text-shadow: 3px 0px 7px rgba(81, 67, 21, 0.8),
    -3px 0px 7px rgba(81, 67, 21, 0.8), 0px 4px 7px rgba(81, 67, 21, 0.8);
  user-select: none;
`;

export const Wrapper = styled.div`
  margin-top: 3rem;
  width: 100%;
  height: calc(100vh - 3.1rem);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: auto;
`;

export const LeftWrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
`;

export const RightWrapper = styled.div`
  background: rgb(235, 236, 240);
  display: flex;
  min-height: 100%;
  flex-grow: 1;
  flex-direction: column;
`;

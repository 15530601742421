import React, { useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import FormGroup from "../../../../Components/ReUsableComponents/FormGroup";
import FormCard from "../../../../Components/ReUsableComponents/FormCard";
import Form from "../../../../Components/ReUsableComponents/Form";
import FormLabel from "../../../../Components/ReUsableComponents/FormLabel";
import { Input } from "../../../../Components/ReUsableComponents/Input";
import Button from "../../../../Components/ReUsableComponents/Button";
import { upperFirst } from "lodash-es";
import { yupResolver } from "@hookform/resolvers/yup";
import FormErrorLabel from "../../../../Components/ReUsableComponents/FormErrorLabel";
import { changePassword } from "../../../../Services/userService";
import { openAlert } from "../../../../Redux/Slices/alertSlice";

const schema = yup.object().shape({
  password: yup.string().required("Current Password is required"),
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const ChangePassword = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userInfo);

  const {
    register,
    setValue,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    changePassword({
      password: data.password,
      newPassword: data.newPassword,
    })
      .then((res) => {
        if (res.data) {
          dispatch(
            openAlert({
              message: "Password changed successfully",
              severity: "success",
              duration: 500,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          openAlert({
            message: err.response.data.message,
            severity: "error",
            duration: 500,
          })
        );
      });
  };

  return (
    <FormCard size="lg">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormLabel>Current Password</FormLabel>
          <Input {...register("password")} type="password" />
          {errors.password && (
            <FormErrorLabel>{errors.password.message}</FormErrorLabel>
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel>New Password</FormLabel>
          <Input {...register("newPassword")} type="password" />
          {errors.newPassword && (
            <FormErrorLabel>{errors.newPassword.message}</FormErrorLabel>
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel>Confirm Password</FormLabel>
          <Input {...register("confirmPassword")} type="password" />
          {errors.confirmPassword && (
            <FormErrorLabel>{errors.confirmPassword.message}</FormErrorLabel>
          )}
        </FormGroup>
        <FormGroup row>
          <Button color={"success"}>Change</Button>
        </FormGroup>
      </Form>
    </FormCard>
  );
};

export default ChangePassword;

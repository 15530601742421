import { Avatar, Box, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
import styled from "styled-components";
import Button from "../../../Components/ReUsableComponents/Button";
import { Clear, Edit } from "@mui/icons-material";
import UploadButton from "../../../Components/ReUsableComponents/UploadButton";
import { updateAvatar } from "../../../Services/userService";
import { updateUserInfor } from "../../../Redux/Slices/userSlice";
import { useDispatch } from "react-redux";
import { openAlert } from "../../../Redux/Slices/alertSlice";
import { original } from "@reduxjs/toolkit";

const Conatiner = styled.div`
  position: relative;
`;

const EditButton = styled(Button)`
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 0.5em;
`;

const AvatarEditor = ({ avatar }) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const handleFileSelected = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setImage(null);
    setCroppedArea(null);
  };

  const saveHandle = () => {
    updateAvatar({
      image,
      ...croppedArea,
    })
      .then((res) => {
        dispatch(updateUserInfor({ avatar: { ...res.data, original: image } }));
        setIsEdit(false);
        setImage(null);
        setCroppedArea(null);
      })
      .catch((err) => {
        dispatch(
          openAlert({
            message: err?.response?.data?.errMessage
              ? err.response.data.errMessage
              : err.message,
            severity: "error",
          })
        );
      });
  };

  return (
    <Conatiner>
      <Avatar
        style={{
          width: 200,
          height: 200,
        }}
        src={avatar?.original || avatar?.thumbnail || avatar?.small}
      />
      <EditButton onClick={() => setIsEdit(true)}>
        <Edit fontSize="small" />
      </EditButton>
      {isEdit && (
        <Modal
          open={true}
          onClose={() => setIsEdit(false)}
          style={{ overflow: "auto" }}
        >
          <Box
            sx={{
              position: "absolute",
              borderRadius: 2,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="confirm-modal-title" variant="h6" component="h2">
              Change avatar
            </Typography>
            <Box
              sx={{
                position: "relative",
                width: 300,
                height: 300,
                margin: "1em auto",
              }}
            >
              {image && (
                <Button
                  color={"danger"}
                  style={{ position: "absolute", top: 0, right: 0, zIndex: 99 }}
                  onClick={() => setImage(null)}
                >
                  <Clear />
                </Button>
              )}
              {image ? (
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  maxZoom={20}
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape="round"
                  cropSize={{ width: 300, height: 300 }}
                  objectFit="cover"
                />
              ) : (
                <UploadButton onFileSelected={handleFileSelected} />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
                gap: 1,
              }}
            >
              <Button sx={{ mr: 2 }} color={"info"} onClick={cancelEdit}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={saveHandle}
                disabled={!image}
              >
                Set your new avatar
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </Conatiner>
  );
};

export default AvatarEditor;

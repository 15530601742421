import React, { useEffect, useState } from "react";
import FiltersDrawer from "./FiltersDrawer";
import { FilterAlt } from "@mui/icons-material";
import Button from "../ReUsableComponents/Button";
import { useDispatch, useSelector } from "react-redux";

export default () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const filters = useSelector((state) => state.board.filters);
  const [active, setActive] = useState(false);
  const handleClick = (event) => {
    setOpen((state) => !state);
  };
  useEffect(() => {
    if (filters.labels?.length > 0 || filters.mentions?.length > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [filters]);
  return (
    <>
      <Button color={active ? "success" : "default"} onClick={handleClick}>
        <FilterAlt fontSize="small" /> Filter
      </Button>
      <FiltersDrawer show={open} closeCallback={handleClick} />
    </>
  );
};

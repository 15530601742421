import React, { useEffect, useRef, useState } from "react";
import { AddLabel, Container, RowContainer } from "./styled";
import BaseDrawer from "../Drawers/BaseDrawer";
import { FilterAlt } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Title } from "./styled";
import { FeatureContainer } from "./styled";
import { Label } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { updatefilters } from "../../Redux/Slices/boardSlice";
import Button from "../ReUsableComponents/Button";
import LabelIcon from "@mui/icons-material/LabelOutlined";
import MemberIcon from "@mui/icons-material/PersonOutlineOutlined";
import BasePopover from "../ReUsableComponents/BasePopover";
import LabelsPopover from "../Popovers/LabelsPopover";
import MembersPopover from "../Popovers/MembersPopover";
import AvatarIcon from "../AvatarIcon";

const FiltersDrawer = (props) => {
  const [show, setShow] = useState(false);
  const [labelPopover, setLabelPopover] = React.useState(null);
  const [memberPopover, setMemberPopover] = React.useState(null);
  const { labels, members, filters } = useSelector((state) => state.board);
  const ref = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    props.show && setShow(true);
  }, [props.show]);

  const labelFilterChange = (label) => {
    const labels = filters.labels || [];
    if (labels.map((s) => s._id).includes(label._id)) {
      dispatch(
        updatefilters({
          labels: labels.filter((item) => item._id !== label._id),
        })
      );
    } else {
      dispatch(updatefilters({ labels: [...labels, label] }));
    }
  };

  const membersFilterChange = (member) => {
    const selected = filters.mentions || [];
    if (selected.includes(member.user)) {
      dispatch(
        updatefilters({
          mentions: selected.filter((item) => item !== member.user),
        })
      );
    } else {
      dispatch(updatefilters({ mentions: [...selected, member.user] }));
    }
  };
  return (
    <BaseDrawer
      title={
        <>
          <IconButton
            aria-label="notifications"
            aria-controls="notification-menu"
            aria-haspopup="true"
          >
            <FilterAlt
              sx={{
                color: "black",
              }}
            />
          </IconButton>
          Filters
        </>
      }
      show={show}
      closeCallback={(param) => {
        setShow(param);
        props.closeCallback();
      }}
      content={
        <Container>
          <FeatureContainer>
            <Title>Labels</Title>
            <RowContainer>
              {filters.labels?.map((label) => {
                return (
                  <Label
                    key={"show_" + label._id}
                    color={label.color}
                    hoverColor={label.backColor}
                  >
                    {label.text}{" "}
                    <ClearIcon
                      sx={{
                        cursor: "pointer",
                        ":hover": {
                          color: "red",
                        },
                      }}
                      fontSize="small"
                      onClick={() => labelFilterChange(label)}
                    />
                  </Label>
                );
              })}

              <Button
                color={"default"}
                onClick={(event) => setLabelPopover(event.currentTarget)}
              >
                <LabelIcon fontSize="small" /> Labels
                {filters.labels?.length > 0 && `(${filters.labels?.length})`}
              </Button>
              {labelPopover && (
                <BasePopover
                  anchorElement={labelPopover}
                  closeCallback={() => {
                    setLabelPopover(null);
                  }}
                  contents={
                    <LabelsPopover
                      labels={labels}
                      selectedItems={filters.labels?.map((s) => s._id)}
                      handleItemClick={labelFilterChange}
                    />
                  }
                />
              )}
            </RowContainer>
          </FeatureContainer>
          <FeatureContainer>
            <Title>Members</Title>
            <RowContainer>
              {filters.mentions?.map((member) => {
                const memberData = members.find((s) => s.user === member);
                return (
                  memberData && (
                    <Label key={"show_" + member} color={"#5e6c84"}>
                      <AvatarIcon
                        {...memberData}
                        sx={{
                          width: "20px",
                          height: "20px",
                          fontSize: "0.7rem",
                        }}
                      />
                      {memberData.name}{" "}
                      <ClearIcon
                        sx={{
                          cursor: "pointer",
                          ":hover": {
                            color: "red",
                          },
                        }}
                        fontSize="small"
                        onClick={() => membersFilterChange(memberData)}
                      />
                    </Label>
                  )
                );
              })}
            </RowContainer>
            <RowContainer>
              <Button
                color={filters.mentions.length > 0 ? "success" : "default"}
                onClick={(event) => setMemberPopover(event.currentTarget)}
              >
                <MemberIcon fontSize="small" /> Members
                {filters.mentions.length > 0 && `(${filters.mentions.length})`}
              </Button>
            </RowContainer>

            {memberPopover && (
              <BasePopover
                anchorElement={memberPopover}
                closeCallback={() => {
                  setMemberPopover(null);
                }}
                title="Members"
                contents={
                  <MembersPopover
                    members={members}
                    selectedItems={filters.mentions}
                    handleItemClick={membersFilterChange}
                  />
                }
              />
            )}
          </FeatureContainer>
        </Container>
      }
    />
  );
};

export default FiltersDrawer;

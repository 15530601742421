import axios from "axios";
import { openAlert } from "../Redux/Slices/alertSlice";
import {
  failFetchingBoards,
  startFetchingBoards,
  successFetchingBoards,
} from "../Redux/Slices/boardsSlice";
import {
  setLoading,
  successFetchingBoard,
  updateBoard,
  updateTitle,
} from "../Redux/Slices/boardSlice";
const baseUrl = process.env.REACT_APP_API_URL + "/board";

export const getBoards = async (fromDropDown, dispatch) => {
  if (!fromDropDown) dispatch(startFetchingBoards());
  try {
    const res = await axios.get(baseUrl + "/");
    setTimeout(() => {
      dispatch(successFetchingBoards({ boards: res.data }));
    }, 1000);
  } catch (error) {
    dispatch(failFetchingBoards());
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const createBoard = async (props, dispatch) => {
  return axios.post(baseUrl + "/create", props);
};

export const getBoard = async (boardId, dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await axios.get(baseUrl + "/" + boardId);
    dispatch(successFetchingBoard(res.data));
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 1000);
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const boardTitleUpdate = async (title, boardId, dispatch) => {
  try {
    dispatch(updateBoard({ title }));
    await axios.put(baseUrl + "/" + boardId + "/update-board-title", {
      title: title,
    });
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const boardDelete = async (boardId, dispatch) => {
  try {
    await axios.delete(baseUrl + "/" + boardId + "/delete");
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const cardTransfer = async (data, dispatch) => {
  try {
    return await axios.post(baseUrl + "/card-transfer", data);
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const myAdminBoards = async () => {
  try {
    return await axios.get(baseUrl + "/my-admin-boards");
  } catch (error) {
    return error;
  }
};

import React, { useEffect, useState } from "react";
import * as style from "./styled";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import * as common from "../../Pages/BoardPage/CommonStyled";
import { useDispatch, useSelector } from "react-redux";
import { boardTitleUpdate } from "../../Services/boardsService";
import RightDrawer from "../Drawers/RightDrawer/RightDrawer";
import { boardLink, boardReportLink } from "../../Utils/linkHelper";
import Button from "../ReUsableComponents/Button";
import { FilterAlt, Height, User } from "@mui/icons-material";
import AvatarIcon from "../AvatarIcon";
import MyTasksButton from "../MyTasksButton";
import BoardFilters from "../BoardFilters";
import { CardAutoTimeTrackingBar } from "../AutoTimeTracking";

const TopBar = ({ activeMenu }) => {
  const board = useSelector((state) => state.board);
  const [currentTitle, setCurrentTitle] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const user = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();
  const loggedUser = board.members.find((m) => m.user === user._id);
  useEffect(() => {
    setCurrentTitle(board.title);
  }, [board]);
  const handleTitleChange = () => {
    if (!["admin", "owner"].includes(loggedUser.role)) return;
    boardTitleUpdate(currentTitle, board.id, dispatch);
  };

  const myTasksClick = () => {
    console.log("My Tasks Clicked");
  };

  return (
    <style.TopBar>
      <style.LeftWrapper>
        <style.BoardNameInput
          placeholder="Board Name"
          value={currentTitle}
          onChange={(e) => setCurrentTitle(e.target.value)}
          onBlur={handleTitleChange}
          disabled={!["admin", "owner"].includes(loggedUser.role)}
        />
        <style.Button
          color={activeMenu !== "b" && "clasic"}
          active={activeMenu == "b"}
          to={boardLink(board.shortId, board.title)}
        >
          TASKS
        </style.Button>
        <style.Button
          color={activeMenu !== "r" && "clasic"}
          active={activeMenu == "r"}
          to={boardReportLink(board.shortId, board.title)}
        >
          REPORT
        </style.Button>
        <CardAutoTimeTrackingBar />
      </style.LeftWrapper>

      <style.RightWrapper>
        {activeMenu == "b" && (
          <>
            <MyTasksButton />
            <BoardFilters />
          </>
        )}
        <common.Button
          onClick={() => {
            setShowDrawer(true);
          }}
        >
          <MoreHorizIcon />
          <style.TextSpan>Show menu</style.TextSpan>
        </common.Button>
      </style.RightWrapper>
      <RightDrawer
        show={showDrawer}
        closeCallback={() => {
          setShowDrawer(false);
        }}
      />
    </style.TopBar>
  );
};

export default TopBar;

import styled from "styled-components";

export const TopAreaContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;
`;

export const AvatarContainer = styled.div`
  aspect-ratio: 1 / 1;
  height: 100%;
  position: relative;
  min-height: 200px;
`;

export const InforContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

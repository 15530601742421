import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  LeftContainer,
  RightContainer,
  Title,
  CommentWrapper,
  ButtonContainer,
  LinkContainer,
  Link,
  CommentEditorContainer,
  TimeAgoText,
  CommentTime,
  CommentContainer,
} from "./styled";
import {
  commentDelete,
  commentUpdate,
  uploadSingleAttachment,
} from "../../../../Services/cardService.js";
import { Avatar } from "@mui/material";
import moment from "moment";
import BottomButtonGroup from "../../../BottomButtonGroup/BottomButtonGroup.js";
import QuillEditor from "../../../QuillEditor/index.jsx";
import AvatarIcon from "../../../AvatarIcon.jsx";

const Comment = (props) => {
  const board = useSelector((state) => state.board);
  const [edit, setEdit] = useState(true);
  const [mentions, setMentions] = useState([]);
  const [comment, setComment] = useState(props.text);
  const user = useSelector((state) => state.user.userInfo);
  const card = useSelector((state) => state.card);
  const dispatch = useDispatch();
  const handleSaveClick = async () => {
    setEdit(true);
    await commentUpdate(
      card.cardId,
      card.boardId,
      {
        content: comment,
        mentions,
      },
      props._id,
      dispatch
    );
  };

  const handleDeleteClick = async () => {
    await commentDelete(card.cardId, card.boardId, props._id, dispatch);
  };

  const uploadImageHandler = async (file) => {
    const res = await uploadSingleAttachment(
      card.cardId,
      board.shortId,
      file,
      dispatch
    );

    return res.data.attachments[res.data.attachments.length - 1].link;
  };

  return (
    <>
      <Container>
        <LeftContainer>
          <AvatarIcon id={props.sender.user} {...props.sender} />
        </LeftContainer>
        <RightContainer>
          <div
            style={{ display: "flex", flexDirection: "row", alignItems: "end" }}
          >
            <Title>{props.sender.name}</Title>

            {Date.now() - new Date(props.date) > 60 * 60 * 1000 ? (
              <CommentTime>
                {moment(props.date).format("MMMM Do YYYY, h:mm:ss a")}
              </CommentTime>
            ) : (
              <TimeAgoText date={props.date} />
            )}
          </div>
          <CommentWrapper>
            {!edit ? (
              <CommentEditorContainer>
                <QuillEditor
                  onChanged={(e) => setComment(e)}
                  value={comment}
                  placeholder="Mention with @, Write a comment..."
                  onMention={(e) => setMentions(e)}
                  users={board.members
                    .filter((u) => u.user != user._id)
                    .map((member) => ({
                      id: member.user,
                      value: member.name,
                    }))}
                  uploadImageHandler={uploadImageHandler}
                />
              </CommentEditorContainer>
            ) : (
              //   <CommentArea
              //     value={comment}
              //     onChange={(e) => setComment(e.target.value)}
              //     readOnly={edit}
              //   />
              <CommentContainer dangerouslySetInnerHTML={{ __html: comment }} />
              // <CommentArea value={comment} onChange={(e) => setComment(e.target.value)} readOnly={edit} />
            )}
            {/* <CommentArea value={comment} onChange={(e) => setComment(e.target.value)} readOnly={edit} /> */}
            <ButtonContainer show={!edit}>
              <BottomButtonGroup
                title="Save"
                clickCallback={handleSaveClick}
                closeCallback={() => {
                  setEdit(true);
                }}
              />
            </ButtonContainer>
            <LinkContainer show={edit && user._id === props.sender.user}>
              <Link onClick={() => setEdit(false)}>Edit</Link>
              <Link onClick={handleDeleteClick}>Delete</Link>
            </LinkContainer>
          </CommentWrapper>
        </RightContainer>
      </Container>
    </>
  );
};

export default Comment;

import styled from "styled-components";
import { md, sm, xs } from "../../BreakPoints";
import { Link } from "react-router-dom";
export const CardBarContainer = styled.div`
  height: 2.2em;
`;

export const CardBarContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0;
  overflow-y: none;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 3px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

export const CardBarTitle = styled(Link)`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  line-height: 1em;
  font-weight: bold;
  color: #fff;
  padding: 3px 5px 3px 0;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  ${md({
    display: "none",
  })}
`;

export const CardBarComment = styled.span`
  font-size: 0.75rem;
  color: #000;
  font-weight: 500;
`;

export const EditInput = styled.input`
  width: 100%;
  height: 2rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding-left: 0.5rem;
  outline: none;
  font-size: 0.875rem;
  background-color: #fff;
  margin-right: 0.5em;
`;

export const CommentContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5em;
`;

export const CommentContent = styled.div`
  padding: 0.5rem;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 0.5em;
  svg {
    cursor: pointer;
    &:hover: {
      color: #0079bf;
    }
  }
`;

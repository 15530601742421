import styled from "styled-components";
import { md } from "../../BreakPoints";

export default styled.div`
  box-sizing: border-box;
  display: block;
  width: 100%;
  max-width: ${(props) => {
    switch (props.size) {
      case "full": {
        return "100%";
      }
      case "xl": {
        return "1200px";
      }
      case "lg": {
        return "1024px";
      }
      case "md": {
        return "768px";
      }
      case "sm":
      default: {
        return "400px";
      }
    }
  }};
  margin: 0 auto;
  position: relative;
  background-color: #ffffff;
  border-radius: 3px;
  padding: 1.5rem 2.5rem;
  box-shadow: rgb(0 0 0 / 10%) 0 0 10px;
  margin-top: 1em;
  ${md({
    maxWidth: "100%",
    width: "100%",
    boxShadow: "none",
    backgroundColor: "#F9FAFC",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    padding: "0.5rem 1rem",
  })}
`;

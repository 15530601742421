// create AutoTimeTracking context

import React, { createContext, useContext, useEffect, useState } from "react";
import Button from "../ReUsableComponents/Button";
import { Stop } from "@mui/icons-material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  CardBarContent,
  CardBarContainer,
  CardBarTitle,
  CardBarComment,
} from "./styled";
import { cardLink } from "../../Utils/linkHelper";
import TaskComment from "./TaskCommen";
import { addWorkingTime, updateWorkingTime } from "../../Services/cardService";

export const AutoTimeTrackingContext = createContext({
  cardData: null,
  lastSyncTime: null,
  startTime: null,
  start: () => {},
  stop: () => {},
  pause: () => {},
  setComment: () => {},
});

export const AutoTimeTrackingProvider = ({ children, boardId }) => {
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [lastSyncTime, setLastSyncTime] = useState(null);
  const [time, setTime] = useState({});

  const start = (cardId) => {
    setCardData({
      boardId,
      cardId,
      comment: "",
    });

    addWorkingTime(cardId, boardId, 60, time.comment, new Date(), dispatch)
      .then((data) => {
        const autoTime =
          data.data.timeTracking.userTimeTracking[
            data.data.timeTracking.userTimeTracking.length - 1
          ];
        setTime(autoTime);
        setStartTime(new Date());
        setLastSyncTime(new Date());
      })
      .catch((err) => {
        stop();
      });
  };

  const stop = () => {
    const seconds = dayjs(new Date()).diff(startTime, "second");
    if (time && time._id) {
      updateWorkingTime(
        cardData.cardId,
        boardId,
        time._id,
        seconds,
        cardData.comment,
        null,
        dispatch
      );
    }
    setCardData(null);
    setStartTime(null);
    setLastSyncTime(null);
  };

  const syncDataToServer = () => {
    // send data to server

    if (time && time._id) {
      const seconds = dayjs(new Date()).diff(startTime, "second");
      updateWorkingTime(
        cardData.cardId,
        boardId,
        time._id,
        seconds,
        cardData.comment,
        null,
        dispatch
      );
      setLastSyncTime((state) => new Date());
    } else {
      stop();
    }
  };

  const setComment = (comment) => {
    if (time && time._id) {
      setCardData((state) => ({
        ...state,
        comment,
      }));
      const seconds = dayjs(new Date()).diff(startTime, "second");
      updateWorkingTime(
        cardData.cardId,
        boardId,
        time._id,
        seconds,
        comment,
        null,
        dispatch
      );
    } else {
      stop();
    }
  };

  useEffect(() => {
    if (cardData) {
      setTimeout(() => {
        syncDataToServer();
      }, 60 * 1000);
    }
  }, [lastSyncTime]);

  return (
    <AutoTimeTrackingContext.Provider
      value={{
        cardData,
        lastSyncTime,
        startTime,
        start,
        stop,
        setComment,
      }}
    >
      {children}
    </AutoTimeTrackingContext.Provider>
  );
};

export const CardAutoTimeTrackingButton = ({ cardId }) => {
  const { start, stop, startTime, cardData } = useContext(
    AutoTimeTrackingContext
  );
  const [lastShownTime, setLastShownTime] = useState(null);

  const updateLastShownTime = () => {
    setLastShownTime(new Date());
  };

  useEffect(() => {
    if (startTime) {
      setLastShownTime(new Date());
    }
  }, [startTime]);

  useEffect(() => {
    if (startTime) {
      setTimeout(() => {
        updateLastShownTime();
      }, 1000);
    }
  }, [lastShownTime]);

  if (!cardData || cardId !== cardData?.cardId) {
    return <Button onClick={() => start(cardId)} title="Start Timer" />;
  }

  const hours = dayjs(lastShownTime).diff(startTime, "hour");
  const minutes = dayjs(lastShownTime).diff(startTime, "minute") % 60;
  const seconds = dayjs(lastShownTime).diff(startTime, "second") % 60;

  return (
    <Button onClick={stop} active={true}>
      <Stop fontSize="small" />
      {lastShownTime
        ? `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
        : "Starting..."}
    </Button>
  );
};

export const CardAutoTimeTrackingBar = () => {
  const { cardData, startTime } = useContext(AutoTimeTrackingContext);
  const board = useSelector((state) => state.board);
  const cards = useSelector((state) => state.board.cards);
  if (!cardData && !startTime) {
    return null;
  }

  const card = cards[cardData.cardId];

  return (
    <CardBarContainer>
      <CardBarContent>
        <CardAutoTimeTrackingButton cardId={cardData.cardId} />
        {card && (
          <CardBarTitle
            to={cardLink(board.shortId, board.title, card._id, card.title)}
          >
            {card.title}
            <CardBarComment>{cardData.comment}</CardBarComment>
          </CardBarTitle>
        )}
      </CardBarContent>
    </CardBarContainer>
  );
};

export const CardAutoTrackingComment = () => {
  const { cardData, setComment } = useContext(AutoTimeTrackingContext);

  if (!cardData) {
    return <div></div>;
  }

  return <TaskComment text={cardData.comment} onChanged={setComment} />;
};

const useAutoTimeTracking = () => {
  return useContext(AutoTimeTrackingContext);
};

export default useAutoTimeTracking;
